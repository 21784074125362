import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  GET_AVAILABLE_LOCATION_JOBS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/inspectors/get-available-jobs", { params: payload })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  PICK_JOB({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/inspectors/pick-a-job/${orderId}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  GET_MY_JOBS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/inspectors/get-my-jobs", { params })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  GET_SPECIFIC_JOB({ commit }, jobId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/inspectors/get-job/" + jobId)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  BLOCK_OUT_DATES({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/inspectors/block-out-dates", data)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  UNBLOCK_DATES({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/inspectors/unblock-dates", data)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  GET_BLOCKED_DATES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/inspectors/get-calendar-blocked-dates", { params })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  GET_CALENDAR_EVENTS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/inspectors/get-calendar-events", { params })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations
};
