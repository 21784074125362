<template>
  <modal
    name="subscribe-to-newsletter"
    classes="modal-container"
    content-class="modal-content max-width-610"
    v-model="isModalVisible"
  >
    <ModalHeader
      name="subscribe-to-newsletter"
      title="Subscribe To Newsletter"
    />
    <div class="padding-10">
      <form @submit.prevent="handleSubscribeToNewLsetter">
        <div className="row">
          <div>
            <h3 className="padding-bottom-10">Be the first to know.</h3>
            <div className=" padding-bottom-10">
              Subscribe to our newsletter to receive articles on our services.
            </div>
          </div>
          <div className="row align-items-center">
            <div className="padding-right-0 padding-right-sm-20">
              <div>
                <input
                  className="form-control padding-top-14 padding-bottom-14 font-size-16"
                  placeholder="Email Address"
                  name="email"
                  v-model="email"
                />
              </div>
              <div>
                <span
                  :class="{ 'form-error': !v$.email.email }"
                  v-if="!v$.email.email"
                  >Email is invalid.</span
                >
                <span
                  :class="{
                    'form-error': v$.email.required.$invalid && v$.email.$error,
                  }"
                  v-if="v$.email.required.$invalid && v$.email.$error"
                  >Email is required.</span
                >
              </div>
            </div>
            <div
              className="padding-top-20 padding-bottom-20 padding-top-md-0 padding-bottom-md-0"
            >
              <button
                className="btn bg-logo-blue white font-size-16 max-width-140 border-radius-6 padding-14"
                type="submit"
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="d-flex margin-top-10 margin-bottom-10">
            <input
              class="margin-right-10"
              type="checkbox"
              :value="!isNewsletterPopupBlocked"
              @change="
                () => {
                  isNewsletterPopupBlocked = !isNewsletterPopupBlocked;
                  handleBlockNewsletterPopup(isNewsletterPopupBlocked);
                }
              "
            />
            <label>Don't show this popup again.</label>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapState } from "vuex";
import ModalHeader from "./ModalHeader.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    ModalHeader,
  },
  data() {
    return {
      isModalVisible: false,
      isNewsletterPopupBlocked: false,
      email: "",
    };
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    handleBlockNewsletterPopup(value) {
      localStorage.setItem("blockNewsletterPopup", value);
    },
    handleSubscribeToNewLsetter() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const loading = this.$loading.show();
      this.$store
        .dispatch("newsletter/SUBSCRIBE_TO_NEWSLETTER", {
          email: this.email,
        })
        .then((res) => {
          loading.hide()
          this.handleBlockNewsletterPopup(true);
          this.$notify({
            group: "general",
            text: res.message,
            type: "success",
          });
          this.$modal.hide("subscribe-to-newsletter");
        })
        .catch((error) => {
          loading.hide()
          this.$notify({
            group: "general",
            text: error.message,
            type: "error",
          });
        });
    },
  },
  mounted() {
    let isBlocked = localStorage.getItem("blockNewsletterPopup");
    let nextTimeToShowNewlsetterPopUp = localStorage.getItem(
      "nextTimeToShowNewsletterPopUp"
    );

    if (nextTimeToShowNewlsetterPopUp) {
      nextTimeToShowNewlsetterPopUp = new Date(
        parseInt(nextTimeToShowNewlsetterPopUp)
      );
    }

    if (isBlocked) {
      this.isNewsletterPopupBlocked = JSON.parse(isBlocked);
    }

    if (
      this.user &&
      this.user.newsletterSubscribed == true &&
      !this.isNewsletterPopupBlocked
    ) {
      this.handleBlockNewsletterPopup(true);
      this.isNewsletterPopupBlocked = true;
    }

    if (
      !this.isNewsletterPopupBlocked &&
      nextTimeToShowNewlsetterPopUp < new Date()
    ) {
      setTimeout(() => {
        this.isModalVisible = true;
      }, 5000);
    }

    localStorage.setItem(
      "nextTimeToShowNewsletterPopUp",
      new Date().getTime() + 86400000 //In 24 hours time
      // new Date().getTime() + 100000
    );
  },
};
</script>

<style lang="scss" scoped>
</style>
