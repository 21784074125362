import axiosInstance from "../helpers/axiosInstance";

const state = {
  localTracks: null
};

const mutations = {
  SET_LOCAL_TRACKS: (state, localTracks) => {
    state.localTracks = localTracks;
  },
};

const actions = {
  //Initialize chatrooms
  GET_ONE_TO_ONE_CHAT_ROOMS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/chats/get-my-one-to-one-chat-rooms", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Fetch messages for a chat room
  GET_MESSAGES({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/chats/get-messages/${payload.chatRoomId}`, { params: payload.params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //To send a message whether there is an attachment or not
  SEND_MESSAGE({  }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/chats/send-message`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  },


  //To get the chatroom you are with another user
  GET_ONE_TO_ONE_CHATROOM({ commit }, otherUserId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/chats/get-one-to-one-chat-room/` + otherUserId)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //To sign the key of the file url
  GENERATE_SIGNED_FILE_URL({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/chats/generate-signed-file-url`, data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //To delete a message
  DELETE_CHAT_MESSAGE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/chats/delete-message", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //To search the search for chat rooms
  SEARCH_FOR_ONE_TO_ONE_CHATROOM({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/chats/search-for-one-on-one-chatrooms", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
