import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  CREATE_OR_UPDATE_QUOTATION({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/quotations/create-or-update-quotation", data)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  INSPECTOR_CANCELS_QUOTATION({ commit }, quotationId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/quotations/cancel-quotation/${quotationId}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  CUSTOMER_DECLINES_QUOTATION({ commit }, quotationId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/quotations/decline-quotation/${quotationId}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations
};
