
const state = {
    currentScreenWidth: null,
};

const mutations = {
    SET_CURRENT_SCREEN_WIDTH: (state, payload) => {
        state.currentScreenWidth = payload;
    },

};

const actions = {
//     //Get 
//   GET_ONE_TO_ONE_CHAT_ROOMS({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       axiosInstance.get(`/chats/get-my-chat-rooms?limit=${payload.limit}`)
//         .then((res) => {
//           const { success } = res.data;
//           if (success == true) {

//             resolve(res.data)
//           }
//         }).catch((err) => {
//           reject(err.response.data)
//         })
//     })
//   },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
