<template>
  <div v-if="user">
    <div v-if="user.avatar" class="avatar-image">
      <img
        :src="`${user.avatar}?${new Date()}`"
        style="
          width: 100%; /* make image width 100% of container */
          height: auto;
        "
      />
    </div>
    <div v-else class="avatar-initials">
      {{ user.firstName ? user.firstName.substring(0, 1).toUpperCase() : ""
      }}{{ user.lastName ? user.lastName.substring(0, 1).toUpperCase() : "" }}
    </div>
  </div>
  <div v-else class="avatar-initials"></div>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style lang="scss" scoped>
</style>
