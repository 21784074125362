function setupTawk(show = true) {
  if (show) {

    /**
       * Links for language-specific channels or projects, you can find them in original tawk embed code
       */
    /**
     * 
     * Hide widget if tawk is loaded
     */
    if (
      window.Tawk_API !== undefined &&
      typeof window.Tawk_API.hideWidget === 'function'
    ) {
      window.Tawk_API.showWidget();
    }
    /**
     * Delete script tags of tawk
     */
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const tag = scripts[i];
      if (tag.getAttribute('tawk') === 'yes') {
        tag.parentNode.removeChild(tag);
      }
    }
    /**
     * Delete anything related to tawk, otherwise new widget would not be loaded
     */
    for (const name in window) {
      if (
        window.hasOwnProperty(name) &&
        (name.includes('tawk') || name.includes('Tawk'))
      ) {
        delete window[name];
      }
    }

    /**
     * Almost the same code as original
     */
    window.Tawk_API = {};
    window.Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = process.env.VUE_APP_TAWK_TO_KEY_ENGLISH
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s1.setAttribute('tawk', 'yes'); //This line is used to mark tawk script
      s0.parentNode.insertBefore(s1, s0);
    })()

    window.Tawk_API.onStatusChange = function (status) {
      window.Tawk_API.toggleVisibility();
      //place your code here
      // if (status === 'online') {
      //   document.getElementById('status').innerHTML = '<a href="javascript:void(window.Tawk_API.toggle())">Online - Click to chat</a>';
      // }

    };
  } else {
    if (typeof window.Tawk_API !== "undefined") {
      delete window.Tawk_API;
      delete window.Tawk_LoadStart;
    }
  }
}

export default setupTawk