import axiosInstance from "../helpers/axiosInstance";


const state = {
};

const mutations = {
};

const actions = {
  CREATE_NEW_REFERRAL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/referrals/add-new-referral", payload)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  GET_MY_REFERRALS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/referrals/get-my-referrals", { params })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
