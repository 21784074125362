import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to place an order
  CREATE_NEW_ORDER({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/orders/create-new-order", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to update the activity periods for an order
  UPDATE_ORDER_ACTIVITY_PERIODS({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/orders/change-order-activity-periods", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get their orders
  GET_MY_ORDERS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/orders/get-my-orders", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific order of theirs
  CUSTOMER_GET_AN_ORDER({ }, orderId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/orders/get-order/" + orderId)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer adds new task
  CUSTOMER_ADD_NEW_TASK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/orders/customer-add-new-task", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer edits a task
  CUSTOMER_EDIT_TASK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/orders/customer-edit-task", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer edits the order's changeable details
  CUSTOMER_EDIT_CHANGEABLE_ORDER_DETAILS({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/orders/update-changeable-order-details", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer delete a task
  CUSTOMER_DELETE_TASK({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/orders/${data.orderId}/customer-delete-task/${data.taskId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer submit report issue
  CUSTOMER_SUBMIT_REPORT_ISSUE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/orders/customer-submit-report-issue", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer submit delete report issue
  CUSTOMER_DELETE_REPORT_ISSUE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/orders/customer-delete-report-issue", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //Customer accpets order as complete
  CUSTOMER_ACCEPT_ORDER_COMPLETE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/orders/customer-accept-order-as-complete", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
