<template>
  <span
    v-if="number"
    class="notification-number font-size-10 fixed-width-20 fixed-height-20 padding-2"
  >
    {{ number <= 20 ? number : "20+" }}
  </span>
</template>

<script>
export default {
  props: {
    number: 0,
  },
};
</script>
