<template>
  <div class="home-footer-background-image">
    <div class="text-center">
      <div class="container justify-content-center">
        <div class="container-450 padding-20">
          <h2 class="font-size-30 blue">
            It’s easy to get started. <br />
            Start now.
          </h2>
          <form
            @submit.prevent="onSubmitEmail"
            class="d-flex padding-top-30 justify-content-center border-radius-6 align-items-center padding-2 margin-top-10 margin-bottom-4"
          >
            <input
              class="focus-none font-size-13 border-0 padding-10 border-radius-0"
              required
              type="email"
              placeholder="Enter your email"
              v-model="email"
            />
            <div>
              <button
                type="submit"
                class="btn bg-blue padding-top-4 padding-10 white padding-bottom-4 padding-left-8 padding-right-8"
              >
                Get Started
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <h2 class="padding-10">Company</h2>
          <router-link class="router-link" :to="`/inspector`"
            ><div class="padding-10 cursor-pointer">Become an Inspector</div>
          </router-link>
          <router-link class="router-link" :to="`/blog`"
            ><div class="padding-10 cursor-pointer">Blog</div>
          </router-link>
          <router-link class="router-link" :to="`/about-us`"
            ><div class="padding-10 cursor-pointer">About Us</div>
          </router-link>
          <router-link class="router-link" :to="`/privacy`"
            ><div class="padding-10 cursor-pointer">Privacy Policy</div>
          </router-link>
          <router-link class="router-link" :to="`/terms`"
            ><div class="padding-10 cursor-pointer">Terms & Conditions</div>
          </router-link>
        </div>
        <div class="col-sm-4">
          <h2 class="padding-10">Our Services</h2>
          <router-link class="router-link" :to="`/services/general-purpose`">
            <p class="padding-10 cursor-pointer">General Purpose Inspection</p>
          </router-link>
          <router-link class="router-link" :to="`/services/property`">
            <p class="padding-10 cursor-pointer">Property Inspection</p>
          </router-link>
          <router-link class="router-link" :to="`/services/vehicle`">
            <p class="padding-10 cursor-pointer">Vehicle Inspection</p>
          </router-link>
          <router-link class="router-link" :to="`/services/goods`">
            <p class="padding-10 cursor-pointer">Goods Inspection</p>
          </router-link>
          <router-link class="router-link" :to="`/services/infrastructure`">
            <p class="padding-10 cursor-pointer">Infrastructure Inspection</p>
          </router-link>
        </div>
        <div class="col-sm-4 row">
          <h2 class="padding-10">Contact Details</h2>
          <p class="padding-10">
            <i class="fa fa-map-marker"></i> 18 Osaretin Street, Textile Mill
            <br />
            Road, Benin City, Nigeria
          </p>
          <p class="padding-10 cursor-pointer">
            <a :href="'tel:+2348062989430'">
              <i class="fa fa-phone"></i> +2348062989430</a
            ><br />
            7 Days - 8am - 10pm
          </p>
          <p class="padding-10 cursor-pointer">
            <i class="fa fa-envelope"></i> info@inspectorama.com
          </p>
          <p class="padding-10">
            <i
              class="fa fa-facebook-square facebook-color font-size-24 padding-10"
            ></i>
            <i
              class="fa fa-twitter-square twitter-color font-size-24 padding-10"
            ></i>
            <i class="fa fa-youtube-square red font-size-24 padding-10"></i>
          </p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="text-center">© 2023 Inspectoroma, All Rights Reserved</div>
        <p class="padding-top-10 text-center font-size-12">
          Inspectorama Ltd is an inspection services company registered in
          Nigeria with Registration Number: RC1994890
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    onSubmitEmail() {
      if (this.email) {
        const loading = this.$loading.show();
        this.$store
          .dispatch("user/CHECK_IF_EMAIL_REGISTERED", this.email)
          .then((res) => {
            loading.hide();
            if (res.success) {
              if (res.data.isRegistered) {
                this.$router.push(`/login`);
              } else {
                this.$router.push(`/signup`);
              }
            }
          })
          .catch((error) => {
            loading.hide();
            this.$notify({
              group: "general",
              text: error.message,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
