import { createApp } from 'vue'
import App from './App.vue'
import store from "./store/index"
import mainRouter from "./router-main"
import VueClipboard from 'vue3-clipboard'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VFacebookLogin, { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component-next";
import vue3GoogleLogin from 'vue3-google-login'
import VueAppleLogin from 'vue-apple-login';
import VueGtag from "vue-gtag";
import { vfmPlugin } from 'vue-final-modal'
import TextClamp from 'vue3-text-clamp';
import Notifications from '@kyvg/vue3-notification'
import vueCountryRegionSelect from 'vue3-country-region-select'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import * as moment from 'moment'
import ResizeTextarea from 'resize-textarea-vue3'
import VueStarRating from 'vue-star-rating'
import VueSocials from 'vue-socials';
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import socket from "./socketio-client"
import i18n from './i18n'
import "./facebookLogin.js";

const app = createApp(App)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
// Vue.use(FBSignInButton)

app.use(VueSocials)

app.component('VFacebookLogin', VFacebookLogin)
app.component('VFacebookLoginScope', VFacebookLoginScope)
app.component("vue-star-rating", VueStarRating);

// Vue.use(GSignInButton)

app.use(TextClamp)


// Vue.use(VModal, {
//   dynamicDefaults: {
//     dialog: true,
//     draggable: true,
//     resizable: true,
//     height: 'auto',
//   },
// });

app.use(vfmPlugin({
  key: '$modal',
  componentName: 'Modal',
  dynamicContainerName: 'ModalsContainer'
}))

const isProd = process.env.NODE_ENV == "production"

// app.use(VueAnalytics, {
//   id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
//   router: mainRouter,
//   // disabled: () => {
//   //   if (localStorage.getItem("vue-cookie-accept-decline") == "accept") {
//   //     return false
//   //   } else {
//   //     return true
//   //   }
//   // },
//   debug: {
//     enabled: !isProd,
//     sendHitTask: isProd
//   }
// })

app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    disabled: () => {
      if (localStorage.getItem("vue-cookie-accept-decline") == "accept") {
        return false
      } else {
        return true
      }
    },
  },
  enabled: isProd
},
  mainRouter)

app.use(Notifications)

// Vue.component('vue-phone-number-input', VuePhoneNumberInput);
app.use(VueTelInput,);

// Vue.use(AudioRecorder)

app.use(LoadingPlugin)

const router = () => {
  let routes = mainRouter
  return routes;
};

app.config.globalProperties.$moment = moment;

app.use(vueCountryRegionSelect)

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: "places" // necessary for places input
  }
})

app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID,
  clientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET
})

app.use(VueAppleLogin, {
  clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
  scope: 'name email',
  redirectURI: process.env.VUE_APP_APPLE_REDIRECT_URI,
  state: "state",
  // state: time.toString(),
  usePopup: true,
});

// Vue.prototype.$socket = socket

// app.provide('$socket', socket)
app.config.globalProperties.$socket = socket;

// app.use(VuelidatePlugin)
// app.provide('$v', VuelidatePlugin)

app.use(ResizeTextarea)

app.use(i18n)

app.use(store)

app.use(router())

// new Vue({
//   el: '#app',
//   // router: router(),
//   // store,
//   // i18n,
//   render: h => h(App)
// })

app.mount('#app')
