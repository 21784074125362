import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  GET_BLOG_POSTS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/blog-posts/get-blog-posts", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_SPECIFIC_BLOG_POST({ }, slug) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/blog-posts/get-blog/${slug}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
