import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer or inspector to get a report
  GET_REPORT({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/reports/get-report/" + orderId)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  ADD_FEEDBACK_TASK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/reports/add-feedback-task", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  ADD_FEEDBACK_ENTRY({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/reports/add-feedback-entry", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  EDIT_FEEDBACK_TEXT_ENTRY({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/reports/edit-feedback-text-entry", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  DELETE_FEEDBACK_ENTRY({ commit }, entryId) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete("/reports/delete-feedback-entry/" + entryId)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  MARK_TASK_COMPLETE_OR_INCOMPLETE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/reports/inspector-mark-task-complete-or-incomplete", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  UPDATE_VEHICLE_INSPECTION_REPORT({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/reports/update-vehicle-inspection-report/${data.reportId}`, data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  UPLOAD_VEHICLE_MEDIA({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/reports/upload-vehicle-media", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  DELETE_VEHICLE_MEDIA({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/reports/delete-vehicle-media", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  MARK_REPORT_AS_COMPLETE({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch("/reports/mark-report-complete", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  CHANGE_REPORTED_ISSUE_STATUS({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/reports/change-status-of-issue-raised", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
