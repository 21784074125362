import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  CREATE_SURCHARGE({  }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/surcharges/create-surcharge", data)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  // The surcharge creator cancels the surcharge
  CANCEL_SURCHARGE({  }, surchargeId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/surcharges/cancel-surcharge/${surchargeId}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  // Customer declines surcharge
  DECLINE_SURCHARGE({  }, surchargeId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/surcharges/decline-surcharge/${surchargeId}`)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

    // Customer pays surcharge
    PAY_SURCHARGE({  }, data) {
      return new Promise((resolve, reject) => {
        axiosInstance.post("/surcharges/pay-surcharge", data)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data)
            }
          })
          .catch(err => {
            reject(err.response.data)
          })
      })
    },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations
};
