import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
    //Get assigned privileges
    GET_ASSIGNED_ADMIN_PRIVILEGES() {
        return new Promise((resolve, reject) => {
            axiosInstance.get("/custom-admin/get-assigned-privileges")
                .then((res) => {
                    const { success } = res.data;
                    if (success == true) {

                        resolve(res.data)
                    }
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },

    //List resources
    LIST_RESOURCES({ }, data) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/custom-admin/get-resources-list/${data.resourceName}`, {
                params: data.filter
            })
                .then((res) => {
                    const { success } = res.data;
                    if (success == true) {

                        resolve(res.data)
                    }
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },

    //Get record
    GET_RESOURCE_RECORD({ }, data) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/custom-admin/get-resource-record/${data.resourceName}/${data.recordId}`)
                .then((res) => {
                    const { success } = res.data;
                    if (success == true) {

                        resolve(res.data)
                    }
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
