import axiosInstance from "../helpers/axiosInstance";

const state = {
  balances: [],
  savedBankAccounts: [],
};

const mutations = {
  SET_SAVED_BANK_ACCOUNTS: (state, payload) => {
    state.savedBankAccounts = payload;
  },
  SET_BALANCES: (state, payload) => {
    state.balances = payload;
  },
};

const actions = {
  //For a user to pay to wallet
  CREDIT_PAYMENT_TO_WALLET({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/wallets/credit-payment-to-wallet/${data.paymentReference}`, data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get their wallet history
  GET_WALLET_HISTORY({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/wallets/my-wallet-transactions", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // For a user to get a specific wallet transaction
  GET_WALLET_TRANSACTION({ }, transactionId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`}/wallets/get-wallet-transaction/${transactionId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To fetch the list of payout banks
  GET_AVAILABLE_BANKS({ }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/wallets/get-payout-banks")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To verify a bank account
  VERIFY_BANK_ACCOUNT({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/wallets/verify-bank-account", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To save bank account details
  ADD_BANK_ACCOUNT({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/wallets/add-bank-account", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_SAVED_BANK_ACCOUNTS", res.data.data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To fetch saved bank accounts
  GET_SAVED_BANK_ACCOUNTS({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/wallets/get-saved-bank-accounts")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_SAVED_BANK_ACCOUNTS", res.data.data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To delete a saved bank acccount
  DELETE_BANK_ACCOUNT({ commit }, bankAccountId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/wallets/delete-bank-account/${bankAccountId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_SAVED_BANK_ACCOUNTS", res.data.data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To schedule a payout to a bank account
  TRANSFER_TO_BANK({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/wallets/transfer-to-bank-account", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // To get a transfer fee
  GET_TRANSFER_FEE({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/wallets/get-payout-transfer-fee", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
