import axiosInstance from "../helpers/axiosInstance";
import { getCookie } from "../utility";
import { subscribe } from "../webPushNotifications";
import socket from "../socketio-client";

const state = {
  userToken: null,
  user: null,
  twilioAccessToken: null,
  activateVideoRoomId: null,
  isCallActive: false,
  isActiveCallVisible: false,
  isAppActive: false,
  totalUnreadMessages: {
    oneToOne: 0,
    orderCustomer: 0,
    orderInspector: 0
  },
  noOfUnseenNotifications: 0
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.userToken = token;
  },

  SET_AUTH_USER: (state, user) => {
    state.user = user;
    if (user && user.noOfUnseenNotifications >= 0) {
      state.noOfUnseenNotifications = user.noOfUnseenNotifications
    }
  },

  SET_VIDEO_CALL_DETAILS: (state, details) => {
    state.twilioAccessToken = details.token;
    state.activateVideoRoomId = details.roomId
    state.isCallActive = details.isCallActive
  },

  SET_IS_ACTIVE_CALL_VISIBLE: (state, value) => {
    state.isActiveCallVisible = value
  },

  SET_ACTIVE_ROOM_ID: (state, value) => {
    state.activateVideoRoomId = value
  },

  CLEAR_AUTH_USER: (state) => {
    state.userToken = null;
    state.user = {}
  },

  SET_APP_ACTIVE: (state, value) => {
    state.isAppActive = value
  },

  SET_NO_OF_ALL_UNREAD_MESSAGES: (state, value) => {
    state.totalUnreadMessages = value
  },

  SET_NO_OF_UNSEEN_NOTIFICATIONS: (state, value) => {
    state.noOfUnseenNotifications = value
  }
};

const actions = {
  REGISTER_USER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/signup", payload)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  UPDATE_USER_PROFILE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.put("/users/me/update", payload)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  UPDATE_INSPECTOR_PROFILE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.put("/users/update-inspector-info", payload)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  UPLOAD_INSPECTOR_CREDENTIALS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/upload-inspector-credentials", payload)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  DELETE_INSPECTOR_CREDENTIALS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/delete-inspector-credentials", payload)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  SUBMIT_INSPECTOR_REFERENCE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/submit-inspector-reference", payload)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  DELETE_INSPECTOR_REFERENCE({ commit }, referenceId) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete("/users/delete-inspector-reference/" + referenceId)
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  SUBMIT_INSPECTOR_APPLICATION({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/submit-inspector-application")
        .then(res => {
          if (res.data.success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

  LOGIN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/login", payload)
        .then((res) => {
          const { success, data } = res.data
          if (success == true) {
            commit("SET_TOKEN", data.token)
            commit("SET_AUTH_USER", data.user)
            localStorage.setItem("user", JSON.stringify(data.user))
            socket.connect()
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SOCIAL_MEDIA_LOGIN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/social-media-login", payload)
        .then((res) => {
          const { success, data } = res.data
          if (success == true) {
            commit("SET_TOKEN", data.token)
            commit("SET_AUTH_USER", data.user)
            localStorage.setItem("user", JSON.stringify(data.user))
            socket.connect()
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SEND_PHONE_OTP_PRELOGIN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/send-phone-otp-prelogin", payload)
        .then((res) => {
          const { success, data } = res.data
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  CONFIRM_EMAIL({ commit }, token) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/users/verify_email/${token}`)
        .then((res) => {
          const { success, token, user } = res.data;
          if (success == true) {
            commit("SET_TOKEN", token)
            commit("SET_AUTH_USER", user)
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  RESEND_CONFIRMATION_EMAIL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/resend-email-confirmation", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  REQUEST_PASSWORD_RESET_EMAIL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/request-password-reset-email", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  FETCH_PASSWORD_RESET_PARAMS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/users/password-reset/${payload.id}/${payload.token}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SAVE_NEW_PASSWORD({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/save-new-password", payload)
        .then((res) => {
          const { success, data } = res.data
          if (success == true) {
            commit("SET_TOKEN", data.token)
            commit("SET_AUTH_USER", data.user)
            localStorage.setItem("user", JSON.stringify(data.user))
            socket.connect()
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  CHANGE_PASSWORD({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/change-password", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_USER({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-user")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", data)
            localStorage.setItem("user", JSON.stringify(data))
            resolve()
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  UPLOAD_USER_AVATAR({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/upload-my-avatar", data)
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", data)
            localStorage.setItem("user", JSON.stringify(data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_MY_RECENT_ACTIVITIES() {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/me/recent-activities")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_MY_JOB_ORDER_STATUSES() {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-my-no-of-jobs-and-orders")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_ADDRESS_TIMEZONE({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-address-timezone", {
        params: {
          country: data.country,
          address: data.address
        }
      })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_ADDRESS_FROM_LATLNG({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-address-by-lat-lng", {
        params: data
      })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_SERVICE_DETAILS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-service-details", {
        params: data
      })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SUBMIT_SECURITY_ANSWERS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/save-user-security-answers", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_AUTHENTICATOR_URI() {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-authenticator-app-provisioning-uri")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  CREATE_NEW_AUTHENTICATOR_URI() {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/generate-new-authenticator-app-provisioning-uri")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  PROVISION_AUTHENTICATOR({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/provision-authenticator", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  DISABLE_AUTHENTICATOR({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/disable-authenticator")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  ENABLE_PHONE_2FA({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/enable-phone-2fa", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  DISABLE_PHONE_2FA({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/disable-phone-2fa")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SEND_PHONE_VERIFICATION_CODE({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/send-phone-verification-code")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  VERIFY_PHONE_OTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/verify-phone-otp", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", res.data.data)
            localStorage.setItem("user", JSON.stringify(res.data.data))
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_TWILIO_ACCESS_TOKEN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/api/users/generate_twilio_access_token/${payload.roomId}`)
        .then((res) => {
          const { success, token } = res.data;

          if (success == true) {

            commit("SET_VIDEO_CALL_DETAILS", {
              token,
              roomId: payload.roomId,
              isCallActive: true
            })

            sessionStorage.setItem("twilioAccessToken", token)
            sessionStorage.setItem("activeCallRoomId", payload.roomId)

            resolve(token)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  TRY_AUTO_LOGIN({ commit, dispatch }) {
    let token = getCookie("fUserJwt")

    if (!token) {
      commit("SET_TOKEN", null)
      commit("SET_AUTH_USER", {})
      return
    }

    let user = localStorage.getItem("user")

    if (user && user != "undefined") {
      user = JSON.parse(user)
    }

    commit("SET_TOKEN", token)
    commit("SET_AUTH_USER", user)

    dispatch("GET_USER")



    // let twilioAccessToken = getCookie("twilioAccessToken")

    // // Check if there is an active twilio call session
    // const twilioAccessToken = sessionStorage.getItem("twilioAccessToken")
    // const activeCallRoomId = sessionStorage.getItem("activeCallRoomId")


    // if (twilioAccessToken && activeCallRoomId) {
    //   commit("SET_VIDEO_CALL_DETAILS", {
    //     token: twilioAccessToken,
    //     roomId: activeCallRoomId,
    //     isCallActive: true
    //   })
    // }

    // commit("SET_VIDEO_CALL_DETAILS", twilioAccessToken)
  },

  LOGOUT({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/users/me/logout")
        .then(res => {
          if (res.data.success == true) {
            commit("CLEAR_AUTH_USER")
            localStorage.removeItem("user")
            localStorage.removeItem("token")
            socket.disconnect()
            resolve()
          }
        })
        .catch(err => {
          commit("CLEAR_AUTH_USER")
          localStorage.removeItem("user")
          localStorage.removeItem("token")
          socket.disconnect()
          reject()
        })
    })
  },

  CHECK_IF_EMAIL_REGISTERED({ }, email) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/check-if-email-is-registered", { params: { email } })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_ALL_FAQ({ }, email) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/users/get-all-faq")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  SUBSCRIBE_TO_PUSH_NOTIFICATION({ }) {
    return new Promise(async (resolve, reject) => {
      let subscription = await subscribe()

      const data = {
        deviceRegId: subscription.endpoint,
        messageType: "webPush",
        userP256dhKey: subscription.keys.p256dh,
        userAuthKey: subscription.keys.auth,
      }

      axiosInstance.post("/users/register-push-notification-device", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  INITIATE_USER_ACCOUNT_DELETION({ commit }) {
    return new Promise(async (resolve, reject) => {
      axiosInstance.post("/users/initiate-account-deletion")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  CANCEL_USER_ACCOUNT_DELETION({ commit }) {
    return new Promise(async (resolve, reject) => {
      axiosInstance.post("/users/cancel-account-deletion")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  REGISTER_NOTIFICATION_LAST_SEEN({ commit }) {
    return new Promise(async (resolve, reject) => {
      axiosInstance.post("/users/register-notification-last-seen")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_AUTH_USER", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  GET_RECENT_NOTIFICATIONS({ }, params) {
    return new Promise(async (resolve, reject) => {
      axiosInstance.get("/users/get-recent-notifications", { params })
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
