const register = async () => {
    //Register push
    try {
        let registration
        if ("serviceWorker" in navigator) {
            registration = await navigator.serviceWorker.register("/service-worker.js", {
                scope: "/",

            })
        }
        return registration
    }
    catch (error) {
        console.log("Line 62222222222222222222222", error)
    }
}

const subscribe = async () => {
    //Subscribe
    try {
        const registration = await register()

        if (registration) {
            // We need to ask the user for permission
            const permission = await Notification.requestPermission()

            // If the user accepts, let's create a notification
            if (permission === "granted") {
                // const notification = new Notification("Hi there!");
                // …
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: process.env.VUE_APP_VAPID_PUBLIC_KEY
                })
                
                return subscription.toJSON()
            }
        }
    }
    catch (error) {
        console.log("Line 77777777777777777777777777", error)
    }
}

export { register, subscribe }
