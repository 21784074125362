<template>
  <div>
    <div>
      <div class="home-top-background-image">
        <img
          class="max-width-60 float-end justify-content-center-hidden-sm position: relative"
          src="../assets/images/hero.svg"
        />
        <div class="row">
          <div class="col-sm-6">
            <div class="stretch-full-md padding-left-10">
              <div
                class="font-size-40 line-height-56 font-size-md-64 line-height-md-96 font-weight-400"
              >
                <div class="dark-blue">
                  Eagle-eyed
                  <span class="gold-yellow">Inspection</span> services at your
                  beck and call.
                </div>
              </div>
              <br />
              <p class="font-size-20 line-height-30 font-weight-400 grey">
                We have inspection professionals all across Nigeria to help you
                check or verify your purchase, properties or projects.
              </p>
              <div
                class="row justify-content-center max-width-350 justify-content-between padding-top-20 padding-bottom-20"
              >
                <div class="col-sm-6 margin-top-10 margin-bottom-10">
                  <router-link :to="`/services/general-purpose#quotation`">
                    <button
                      class="btn bg-blue white font-size-16 line-height-24 padding-10 max-width-150 fixed-height-55 border-radius-6"
                      type="button"
                    >
                      Book a Pro &nbsp;
                      <i class="fa fa-angle-right"></i></button
                  ></router-link>
                </div>
                <div class="col-sm-6 margin-top-10 margin-bottom-10">
                  <router-link :to="`/how-it-works`">
                    <button
                      class="btn bg-white black font-size-16 line-height-24 border-color-blue padding-8 max-width-150 fixed-height-55 border-radius-6"
                      type="submit"
                    >
                      How it Works &nbsp; <i class="fa fa-angle-right"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <div>
              <img
                class="max-width-100 float-start justify-content-center-hidden-sm padding-right-20"
                src="../assets/images/Vector.svg"
              />
            </div>
          </div>
          <div class="col-sm-5">
            <img class="max-width-550" src="../assets/images/Hero.png" />
          </div>
        </div>
        <!-- <div class="d-flex font-size-20 line-height-30 font-weight-400 justify-content-center">
          Trusted by over 10,000+ happy clients
        </div> -->
        <!-- <div class="row justify-content-center padding-18 padding-bottom-66">
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="max-width-150"
              src="../assets/images/Google.svg"
            />
          </div>
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="max-width-80 padding-8"
              src="../assets/images/adidas.svg"
            />
          </div>
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8 max-width-200"
              src="../assets/images/corry.svg"
            />
          </div>

          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8 max-width-150"
              src="../assets/images/Amazon.svg"
            />
          </div>

          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8"
              src="../assets/images/Twitter.svg"
            />
          </div>
        </div> -->
      </div>
      <div class="justify-content-center padding-top-40 padding-top-md-40">
        <div class="text-center justify-content-center">
          <div class="red font-size-18 font-weight-400">What we do ?</div>
          <div
            class="font-size-40 line-height-56 font-size-md-64 line-height-md-96 font-weight-400"
          >
            We run all kinds of inspection and expediting services
          </div>
        </div>
      </div>
      <div class="row text-center padding-bottom-0">
        <div
          class="col-lg-4 padding-top-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10"
        >
          <div
            class="container-radius-20 bg-lighter-grey margin-10 padding-md-30"
          >
            <div class="padding-left-10 padding-left-md-0">
              <div
                class="font-size-20 logo-blue font-weight-400 line-height-30 padding-top-10"
              >
                General <span class="black"> Check</span>
              </div>
              <p class="padding-top-10 font-size-14">
                We have a host of general purpose agents for inspection and
                expediting activities
              </p>
            </div>
            <br />
            <div class="padding-bottom-100 padding-top-100">
              <img
                class="max-width-260 margin-bottom-6"
                src="../assets/images/man.png"
              />
            </div>
            <div class="padding-bottom-10 padding-top-34">
              <router-link :to="`/services/general-purpose#quotation`">
                <button
                  class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                  type="submit"
                >
                  <div class="d-flex">
                    <div class="padding-2">Get a quote &nbsp;</div>
                    <img
                      height=""
                      class="margin-bottom-0"
                      src="../assets/images/arrow-right-circl.svg"
                    />
                  </div>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div
          height="100"
          class="col-lg-8 padding-top-20 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10"
        >
          <div class="row">
            <div class="col-md-6 padding-10">
              <div
                class="container-450 container-radius-20 bg-lighter-grey padding-10 padding-md-30"
              >
                <div class="padding-left-10 padding-left-md-0">
                  <div
                    class="font-size-20 logo-blue font-weight-400 line-height-30"
                  >
                    Property <span class="black"> Check</span>
                  </div>
                  <p class="padding-top-10 font-size-14">
                    We can be your eye at building construction sites and do
                    checks on landed properties
                  </p>
                </div>
                <br />
                <div class="padding-top-0">
                  <img
                    class="max-width-150 margin-bottom-6"
                    src="../assets/images/Insurance.png"
                  />
                </div>

                <router-link :to="`/services/property#quotation`">
                  <button
                    class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit"
                  >
                    <div class="d-flex">
                      <div class="padding-2">Get a quote &nbsp;</div>
                      <img
                        height=""
                        class="margin-bottom-0"
                        src="../assets/images/arrow-right-circl.svg"
                      />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-md-6 padding-10">
              <div
                class="container-450 container-radius-20 bg-lighter-grey padding-10 padding-md-30"
              >
                <div class="padding-left-10 padding-left-md-0">
                  <div
                    class="font-size-20 logo-blue font-weight-400 line-height-30"
                  >
                    Vehicle <span class="black"> Check</span>
                  </div>
                  <p class="padding-top-10 font-size-14">
                    Get a professional to check that used vehicle before you
                    make that purchase
                  </p>
                </div>
                <br />
                <div class="padding-top-40 padding-bottom-20">
                  <img
                    class="max-width-160 margin-bottom-6"
                    src="../assets/images/car.png"
                  />
                </div>

                <router-link :to="`/services/vehicle#quotation`">
                  <button
                    class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit"
                  >
                    <div class="d-flex">
                      <div class="padding-2">Get a quote &nbsp;</div>
                      <img
                        height=""
                        class="margin-bottom-0"
                        src="../assets/images/arrow-right-circl.svg"
                      />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 padding-10">
              <div
                class="container-450 container-radius-20 bg-lighter-grey padding-10 padding-md-30"
              >
                <div class="padding-left-10 padding-left-md-0">
                  <div
                    class="font-size-20 font-weight-400 logo-blue line-height-30"
                  >
                    Goods<span class="black"> Check</span>
                  </div>
                  <p class="padding-top-10 font-size-14">
                    We have experienced professionals to ensure supplied goods
                    meet the set standards
                  </p>
                </div>
                <br />
                <div class="padding-top-20 padding-bottom-20">
                  <img
                    class="max-width-150 margin-bottom-6"
                    src="../assets/images/boxes.png"
                  />
                </div>

                <router-link :to="`/services/goods#quotation`">
                  <button
                    class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit"
                  >
                    <div class="d-flex">
                      <div class="padding-2">Get a quote &nbsp;</div>
                      <img
                        height=""
                        class="margin-bottom-0"
                        src="../assets/images/arrow-right-circl.svg"
                      />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-md-6 padding-10">
              <div
                class="container-450 container-radius-20 bg-lighter-grey padding-10 padding-md-30"
              >
                <div class="padding-left-10 padding-left-md-0">
                  <div
                    class="font-size-20 font-weight-400 logo-blue line-height-30"
                  >
                    Infrastruture <span class="black"> Check</span>
                  </div>
                  <p class="padding-top-10 font-size-14">
                    We help government agencies inspect infrastructural projects
                    across the country
                  </p>
                </div>
                <br />
                <div class="padding-top-20 padding-bottom-28">
                  <img
                    class="max-width-190 margin-bottom-6"
                    src="../assets/images/cityscape.png"
                  />
                </div>

                <router-link :to="`/services/infrastructure#quotation`">
                  <button
                    class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit"
                  >
                    <div class="d-flex">
                      <div class="padding-2">Get a quote &nbsp;</div>
                      <img
                        height=""
                        class="margin-bottom-0"
                        src="../assets/images/arrow-right-circl.svg"
                      />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-light-grey padding-top-10 padding-bottom-40">
      <div
        class="d-flex justify-content-center padding-10 padding-top-20 padding-bottom-0 font-size-30 line-height-40 font-size-md-40 line-height-md-50 font-weight-400"
      >
        <span>You want to become an inspector?</span>
      </div>
      <div class="row padding-top-md-0 padding-top-10 padding-bottom-20">
        <div class="col-sm-4 padding-6">
          <div class="bg-black stretch-full-md padding-10 border-radius-6">
            <div
              class="d-flex align-content-center padding-top-20 text-center justify-content-center"
            >
              <img
                src="../assets/images/pay.png"
                class="fixed-width-140 margin-top-20 margin-bottom-20"
              />
            </div>
            <div class="padding-10">
              <div
                class="d-flex justify-content-center font-size-14 font-size-sm-16 padding-top-0 light-grey"
              >
                GREAT PAY
              </div>
              <P class="padding-top-10 grey text-center"
                >Start earning money right away! Gain access to hundreds of
                inspection jobs in your city and build your own schedule
              </P>
            </div>
          </div>
        </div>
        <div class="col-sm-4 padding-6">
          <div class="bg-black stretch-full-md padding-10 border-radius-6">
            <div
              class="d-flex align-content-center padding-top-10 text-center justify-content-center"
            >
              <img
                src="../assets/images/calendar.png"
                class="max-width-80 margin-top-6 margin-bottom-6"
              />
            </div>
            <div class="padding-10">
              <div
                class="d-flex justify-content-center font-size-14 font-size-sm-16 padding-top-10 light-grey"
              >
                FLEXIBLE SCHEDULE
              </div>
              <P class="padding-top-8 text-center grey"
                >You take full charge of your schedule by accepting or rejecting
                your favourite jobs based on your availability
              </P>
            </div>
          </div>
        </div>

        <div class="col-sm-4 padding-6">
          <div class="bg-black stretch-full-md padding-10 border-radius-6">
            <div
              class="d-flex align-content-center padding-top-10 margin-top-md-10 text-center justify-content-center"
            >
              <img src="../assets/images/money.png" class="max-width-80" />
            </div>
            <div class="padding-10">
              <div
                class="d-flex justify-content-center font-size-14 font-size-sm-16 padding-top-10 light-grey"
              >
                SMOOTH PAYMENTS
              </div>
              <P class="text-center padding-top-8 grey"
                >You receive your payment straight into your wallet,
                transferrable to your bank account, soon after the job is
                completed
              </P>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <router-link :to="`/inspector`">
          <button
            class="btn bg-blue white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
            type="button"
          >
            <div class="padding-2">Become an Inspector</div>
          </button>
        </router-link>
      </div>
    </div>

    <div
      class="row justify-content-center padding-10 bg-light-yellow container"
    >
      <div class="text-center">
        <span class="red font-size-18 line-height-22">Our Process</span>
        <div
          class="d-flex justify-content-center font-size-40 line-height-56 font-size-md-64 line-height-md-96 font-weight-400"
        >
          <span> How it Works</span>
        </div>
      </div>
      <div class="row container text-center padding-bottom-40">
        <div
          class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10"
        >
          <div>
            <!-- <img class="margin-bottom-6" src="../assets/images/number.png" /> -->

            <div
              class="d-flex justify-content-center padding-top-44 padding-bottom-44"
            >
              <span
                class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-rich-blue"
                >1</span
              >
            </div>
            <div class="padding-left-10 padding-left-md-0">
              <div
                class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400"
              >
                You Book an Inspector
              </div>
              <p
                class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 grey padding-top-10"
              >
                Tell us the place and what we will inspect or expedite for you
                and make payment to book an inspector
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10"
        >
          <div>
            <!-- <img class="margin-bottom-6" src="../assets/images/number2.png" /> -->
            <div
              class="d-flex justify-content-center padding-top-44 padding-bottom-44"
            >
              <span
                class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-red"
                >2</span
              >
            </div>
            <div class="padding-left-10 padding-left-md-0">
              <div
                class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400"
              >
                We Inspect
              </div>
              <p
                class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 grey padding-top-10"
              >
                An inspector will go to the location, carry out the checks and
                document their findings in accordance with the set standards
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10"
        >
          <!-- <img class="margin-bottom-6" src="../assets/images/number3.png" /> -->
          <div
            class="d-flex justify-content-center padding-top-44 padding-bottom-44"
          >
            <span
              class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-green"
              >3</span
            >
          </div>
          <div class="padding-left-10 padding-left-md-0">
            <div
              class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400"
            >
              You Recieve a Report
            </div>
            <p
              class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 grey padding-top-10"
            >
              After the inspection is complete, a report will be issued and made
              available to you on your dashboard
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row max-width-1300">
      <div
        class="col-md-7 padding-sm-10 padding-bottom-10 padding-bottom-md-10"
      >
        <div class="padding-10">
          <p class="font-size-16 red padding-top-10">Why choose us?</p>
          <br class="d-block-hidden-md" />
          <h1
            class="font-size-30 line-height-46 font-size-md-46 line-height-md-66 font-weight-400"
          >
            We provide the best inspection and expediting services in the
            country
          </h1>
        </div>
      </div>
      <div class="col-md-5 container padding-bottom-0 padding-bottom-md-0">
        <div class="row padding-top-10">
          <div class="col-md-6">
            <div
              class="container-radius-20 bg-white margin-10 padding-20 box-shadow border-radius-8"
            >
              <div class="padding-top-0">
                <img
                  class="max-width-30 margin-bottom-6"
                  src="../assets/images/shield-tick.svg"
                />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">
                  Quality Service
                </p>
                <p
                  class="font-weight-400 font-size-16 line-height-24 grey padding-top-10"
                >
                  Our detailed report gives you insight of happenings and
                  conditions of your project
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 white">
            <div
              class="container-radius-20 bg-blue margin-10 padding-20 border-radius-8"
            >
              <div class="padding-top-0">
                <img
                  class="max-width-30 margin-bottom-6"
                  src="../assets/images/award.svg"
                />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">
                  Qualified Pros
                </p>
                <p
                  class="font-weight-400 font-size-16 line-height-24 white padding-top-10"
                >
                  Our pros are top notch, with the appropriate education and
                  work experience
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="container-radius-20 margin-10 padding-20 box-shadow border-radius-8"
            >
              <div class="padding-top-0">
                <img
                  class="max-width-30 margin-bottom-6"
                  src="../assets/images/cpu-setting.svg"
                />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">
                  Minimal Cost
                </p>
                <p
                  class="font-weight-400 font-size-16 line-height-24 grey padding-top-10"
                >
                  Save overhead cost by using a professional near the locality
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="container-radius-20 margin-10 padding-20 box-shadow border-radius-8"
            >
              <div class="padding-top-0">
                <img
                  class="max-width-30 margin-bottom-6"
                  src="../assets/images/convertshape.svg"
                />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">
                  High Flexibility
                </p>
                <p
                  class="font-weight-400 font-size-16 line-height-24 grey padding-top-10"
                >
                  You can make schedule changes and our inspectors can easily
                  adjust
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="padding-10 row">
      <div class="home-testimonial-row-2-image"></div>
    </div> -->
    <div class="row justify-content-center">
      <div
        class="col-md-6 bg-light-yellow padding-10 padding-top-20 border-bottom-left-radius-10 border-top-left-radius-10"
      >
        <div
          class="font-size-24 line-height-32 font-size-md-32 line-height-md-48 font-weight-400"
        >
          Join countless customers taking charge of their businesses and
          properties by using Inspectorama
        </div>

        <div
          class="d-flex margin-top-md-200 padding-top-70 padding-top-md-180 align-items-end"
        >
          <div>
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/inspectorama/id6456605615"
            >
            <img
              class="max-width-120 margin-bottom-md-10 padding-10"
              src="../assets/images/app-store.png"
            />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.inspectorama"
            >
              <img
                class="max-width-120 margin-bottom-md-10 padding-10 cursor-pointer"
                src="../assets/images/google-play.png"
              />
            </a>
          </div>
          <div></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="home-testimonial-row-1-image"></div>
      </div>
    </div>

    <SubscribeToNewsletter />
  </div>
</template>

<script>
import SubscribeToNewsletter from "./utilityComponents/SubscribeToNewsletter.vue";
export default {
  components: {
    SubscribeToNewsletter,
  },
  data() {
    return {};
  },
  methods: {
    slider() {
      setInterval(() => {
        this.currentSlideState++;
        if (this.currentSlideState > 3) {
          this.currentSlideState = 1;
        }
      }, 5000);
    },
    // onScroll() {
    //   // checking for visibility
    //   if (
    //     this.$refs.row3RightHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow3 = true;
    //   }
    //   if (
    //     this.$refs.row4LeftHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow4 = true;
    //   }
    //   if (
    //     this.$refs.row5RightHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow5 = true;
    //   }
    //   if (
    //     this.$refs.row6LeftHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow6 = true;
    //   }
    //   if (
    //     this.$refs.row7Height.getBoundingClientRect().top < window.innerHeight
    //   ) {
    //     this.animateRow7 = true;
    //   }
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.slider();
      // this.onScroll();
      // window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.onScroll);
  },
};
</script>