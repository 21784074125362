
window.fbAsyncInit = function () {
    FB.init({
        appId: process.env.VUE_APP_FACEBOOK_ID,
        cookie: true, // Enable cookies to allow the server to access the session.
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v15.0'
    });

    FB.getLoginStatus(function (response) {
        // Called after the JS SDK has been initialized.
        
        // statusChangeCallback(response); // Returns the login status.
        return response
    });
};

(function (d, s, id) {
    // Load the SDK asynchronously
    var js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

export default {}