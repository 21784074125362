<template>
  <div class="fixed-height-400">
    <div v-if="myRecentNotifications.length">
      <table class="padding-top-20 border-collapse">
        <tr
          v-for="(notification, index) in myRecentNotifications"
          :key="index"
          :class="!notification.isClicked ? 'bg-light-grey' : 'bg-white'"
          class="border-color-light-grey border-1"
        >
          <div
            class="d-flex padding-10 cursor-pointer"
            @click="
              () => {
                markNotificationAsClicked(notification);
              }
            "
          >
            <i
              class="fa fa-bell font-size-12 logo-blue avatar-initials bg-light-grey min-width-25 max-width-25 fixed-height-25 margin-right-10"
            ></i>
            <div>
              <div>
                {{ notification.message }}
              </div>
              <span class="grey font-size-12">
                {{ toGetRelativeTime(notification.createdAt) }}
              </span>
            </div>
          </div>
        </tr>
      </table>
      <div v-if="isMore" class="d-flex justify-content-start padding-10 bg-white">
        <span
          class="cursor-pointer"
          @click="
            () => {
              $router.push('/notifications');
              this.$emit('exitDropDownMenu');
            }
          "
          >See all notifications</span
        >
      </div>
    </div>
    <div v-else class="d-flex max-width-300">
      <p class="padding-top-200, padding-right-10 padding-left-10">
        There are no recent notifications
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toGetRelativeTime } from "../../../utility";

export default {
  setup() {
    return { toGetRelativeTime };
  },
  data() {
    return {
      myRecentNotifications: [],
      limit: 5,
      isMore: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      noOfUnseenNotifications: (state) => state.user.noOfUnseenNotifications,
    }),
  },
  methods: {
    loadRecentNotifications() {
      // this.$socket.emit("GET_NOTIFICATIONS", { limit: this.limit });

      this.$store
        .dispatch("user/GET_RECENT_NOTIFICATIONS", {
          limit: this.limit,
        })
        .then((res) => {
          if (res.success) {
            if (res.data.length <= this.limit) {
              this.isMore = true;
            }
            this.myRecentNotifications = res.data;
          }
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            group: "general",
            text: error.message,
          });
        });
    },
    markNotificationAsClicked(notification) {
      this.$socket.emit("MARK_NOTIFICATION_AS_CLICKED", notification._id);

      this.myRecentNotifications = this.myRecentNotifications.map((item) => {
        if (item._id == notification._id) {
          item.isClicked = true;
        }
        return item;
      });

      if (notification.actionLink) {
        const split = notification.actionLink.split("/");
        const route = split[3];
        if (route) {
          this.$router.push("/" + route);
          this.$emit("exitDropDownMenu");
        }
      }
    },
  },

  created() {
    this.loadRecentNotifications();

    this.$socket.on("NEW_NOTIFICATION", (newNotification) => {
      if (newNotification) {
        this.myRecentNotifications.pop(); // Remove the last item
        this.myRecentNotifications = [
          newNotification,
          ...this.myRecentNotifications,
        ];
      }
    });
  },
};
</script>

<style scoped></style>