<template>
  <div>
    <div class="justify-content-between align-items-center padding-14">
      <i
        @click="onClose"
        class="fa fa-close float-end font-size-20 red cursor-pointer hover-grey red float-end padding-top-4"
      ></i>
      <h1 class="font-size-20 font-size-md-22">
        {{ title }}
      </h1>
    </div>
    <div class="horizontal-line" />
  </div>
</template>

<script>
export default {
  props: ["name", "title"],
  methods: {
    onClose() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
