import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  CREATE_CUSTOMER_REVIEW({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/customer-reviews/create-customer-review", payload)
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  GET_MY_CUSTOMER_REVIEWS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/customer-reviews/get-my-customer-reviews", { params })
        .then(res => {
          if (res.data.success == true) {
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
