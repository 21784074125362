import momentTimeZone from "moment-timezone";
import moment from 'moment'
import axios from "axios"
import dialingCodesList from "./mockData/dialingCodesList"

const base64ToBlob = (base64DataUrl) => {
  //Split the base64DataUrl to extract the base64 and the contentType
  const base64PictureOnly = base64DataUrl.split(",", 2)[1];
  const contentType = base64DataUrl
    .split(",", 2)[0]
    .split(";")[0]
    .split(":")[1];

  //convert the base64 to byteCharacters
  const byteCharacters = atob(base64PictureOnly);

  //Convert the byteCharacters to byteNumbers
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  //Convert the byte numbers to unit 8 array
  const byteArray = new Uint8Array(byteNumbers);

  //Convert the unit 8 array to blob
  const blob = new Blob([byteArray], { type: contentType })
  return blob
}

const numOfYearsToPastDate = (numOfYears) => {
  return new Date(new Date().getTime() - parseInt(numOfYears) * 31556952000.00043)
}

const dateToNumberOfYearsPassed = (date) => {
  return Math.round((new Date().getTime() - date.getTime()) / 31556952000.00043)
}

const timeLeftToJob = (inspectionDateTime) => {
  return (
    new Date(inspectionDateTime) - new Date()
  );
}

const currentTimeInCity = (cityTimezone) => {
  const yourTimezoneOffset = new Date().getTimezoneOffset() / 60;
  const timezoneOffsetInSelectedCity = parseInt(
    momentTimeZone.tz(new Date(), cityTimezone).format("Z")
  );

  const currentTimeInSelectedCity = new Date(
    new Date().getTime() +
    (yourTimezoneOffset + timezoneOffsetInSelectedCity) * 3.6e+6
  );

  return currentTimeInSelectedCity;
}

//To generate password
const generatePassword = () => {
  var length = 8,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const clearLocalStorageAuth = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("userId")
  localStorage.removeItem("isInspector")
  localStorage.removeItem("typeOfInspector")
  localStorage.removeItem("isInspectorProfileApproved")
  localStorage.removeItem("notificationLastSeen")
  localStorage.removeItem("adminUserId")
  localStorage.removeItem("adminToken")
}

const getUserAbridgedProfile = async (userId) => {
  const response = await axios.get(`/users/get_abridged_profile/${userId}`)
  const profile = response.data
  return profile
}

const getFinetunedLocationDetails = async (data) => {
  const response = await axios
    .post("/quotes/get_location_details", data)
  const location = response.data
  return location
}

const translateValue = (valueToTranslate, localeObject) => {
  let translatedValue = null
  //Receives valueToTranslate and localeObject from the locale
  //Produces the translated value
  const translationArray = convertObjectToArray(localeObject)
  translationArray.forEach(element => {
    if (Object.keys(element) == valueToTranslate) {
      translatedValue = element[valueToTranslate];
    }
  });
  return translatedValue;
}

const convertObjectToArray = (object) => {
  //Receives object = {a: b, c: d}
  //Produces Array = [{a: b}, {c:d}]
  const newArray = Object.entries(object).map(subArr => {
    const newobj = {}
    newobj[subArr[0]] = subArr[1]
    return newobj
  })
  return newArray
}

const transformObjectToValueTypeArray = (object) => {
  //Receives object = {a: b}
  //Produces Array = [{value:a, type: b}]
  const newArray = Object.entries(object).map(subArr => {
    const newobj = {}
    newobj.value = subArr[0]
    newobj.type = subArr[1]
    return newobj
  })
  return newArray
}

const setStripeLocale = (i18nLocale) => {
  if (i18nLocale == "en") {
    return "en"
  } else if (i18nLocale == "no") {
    return "nb"
  }
  else {
    return "en"
  }
}

const doesUserSequenceIdExist = async (sequenceId) => {
  if (sequenceId) {
    const res = await axios.get(`/users/checkSellerSequenceId/${sequenceId}`)
    return res.data.sequenceIdExists
  }
}

const doesUserSequenceIdMatchEmail = async (sequenceId, email) => {
  if (sequenceId && email) {
    const res = await axios.get(`/users/checkSellerSequenceAndEmailMatch/${sequenceId}/${email}`)
    return res.data.isMatch
  }
}

const getAbridgedDialingCodeList = () => {
  dialingCodesList.sort((a, b) => {
    return a.code - b.code;
  })

  const abridgedDialingCodeList = []

  dialingCodesList.forEach(item => {
    if (!abridgedDialingCodeList.includes(item.code)) {
      abridgedDialingCodeList.push(item.code)
    }
  })
  return abridgedDialingCodeList
}

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "")
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const clearAuthCredentials = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("authenticationMethod")
}

const snakeToStandardCasing = (value) => {
  if (value) {
    let splits = value.split("_")
    splits = splits.map(e => {
      return e.toLowerCase().substring(0, 1).toUpperCase() + e.toLowerCase().substring(1)
    })
    splits = splits.join(" ")
    return splits
  } else {
    return value
  }
}

const camelToStandardCasing = (value) => {
  if (value) {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^([a-z])/, function (match, letter) {
      return letter.toUpperCase();
    })
  } else {
    return value
  }
}

const kebabToStandardCasing = (value) => {
  if (value) {
    let splits = value.split("-")
    splits = splits.map(e => {
      return e.toLowerCase().substring(0, 1).toUpperCase() + e.toLowerCase().substring(1)
    })
    splits = splits.join(" ")
    return splits
  } else {
    return value
  }
}

const showTimeOfDay = (value) => {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
}

const toGetRelativeTime = (value) => {
  if (value) {
    return moment(new Date(value), 'MMMM Do YYYY, h:mm:ss a').fromNow()
  } else {
    return value
  }
}

const objectIdToDate = (value) => {
  if (value) {
    const timestamp = value.toString().substring(0, 8)
    return new Date(parseInt(timestamp, 16) * 1000)

  }
}

const toCapitalizeFirstLetter = (value) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

const showDateAndTime = (value) => {
  if (value) {
    return moment(String(value)).format('MMMM Do YYYY, h:mm:ss a')
  }
}

const showOnlyTime = (value) => {
  if (value) {
    return moment(String(value)).format('hh:mm a')
  }
}

const showOnlyYear = (value) => {
  if (value) {
    return moment(String(value)).format('YYYY')
  }
}

const showOnlyMonthAndYear = (value) => {
  if (value) {
    return moment(String(value)).format('MMMM YYYY')
  }
}

const showOnlyDate = (value) => {
  if (value) {
    return moment(String(value)).format('MMMM Do YYYY')
  }
}

const formatDate = (value) => {
  if (value) {
    return moment(String(value)).format('MMM Do, YYYY, h:mm a')
  }
}

// Convert to currency symbol
const formatMoney = (obj) => {
  if (typeof obj.value !== "number") {
    if (typeof parseFloat(obj.value) !== "number") {
      return obj;
    }
  }

  if (obj.currency) {

    const formatData = {
      style: 'currency',
      currency: obj.currency
    }

    if (obj.noDecimal && JSON.parse(obj.noDecimal)) {
      formatData.minimumFractionDigits = 0
      formatData.maximumFractionDigits = 0
    }

    const formatter = new Intl.NumberFormat('en-NG', formatData);

    return formatter.format(obj.value);
  } else {
    return `${obj.value} no-currency`
  }
}

const showDayOfWeek = (value) => {
  if (value) {
    return moment(String(value)).format('dddd')
  }
}

const showVaryingDateFormats = (value) => {
  if (value) {
    let display

    const differenceInHours = moment().diff(String(value), 'hours')
    const isThisYear = moment(String(value)).isSame(new Date(), 'year')

    if (differenceInHours < 24) {
      display = moment(String(value)).format('HH:mm')
    } else {
      if (isThisYear) {
        display = moment(String(value)).format('DD MMM')
      } else {
        display = moment(String(value)).format('DD/MM/YY')
      }
    }
    return display
  }
}

const generatePaymentReference = () => {
  let date = new Date();
  return "PAYMENT" + date.getTime().toString();
}

const calculateNoOfDays = (from, to) => {
  let daysBetweenDates = 0;
  if (from && to && to >= from) {
    const fromDate = moment(from);
    const toDate = moment(to);
    daysBetweenDates = toDate.diff(fromDate, "days") + 1;
  }
  return daysBetweenDates;
}

const countryRegionClasses = (property) => {
  return {
    "form-control": true,
    "grey": property ? false : true,
    'font-weight-bold': property ? true : false,
    'font-weight-normal': property ? false : true,
  }
}

const selectTagClasses = (property) => {
  return {
    "form-control": true,
    "grey": property ? false : true,
    'font-weight-bold': property ? true : false,
    'font-weight-normal': property ? false : true,
    'stretch-full': true
  }
}

const getFileFormatFromMimeType = (mimeType) => {
  if (/pdf/i.test(mimeType)) {
    return "pdf";
  } else if (/image/i.test(mimeType)) {
    return "image";
  } else if (/video/i.test(mimeType)) {
    return "video";
  } else if (/audio/i.test(mimeType)) {
    return "audio";
  } else {
    return null
  }
}

export {
  base64ToBlob,
  numOfYearsToPastDate,
  dateToNumberOfYearsPassed,
  timeLeftToJob,
  currentTimeInCity,
  generatePassword,
  clearLocalStorageAuth,
  getUserAbridgedProfile,
  getFinetunedLocationDetails,
  convertObjectToArray,
  transformObjectToValueTypeArray,
  setStripeLocale,
  translateValue,
  doesUserSequenceIdExist,
  doesUserSequenceIdMatchEmail,
  getAbridgedDialingCodeList,
  getCookie,
  clearAuthCredentials,
  snakeToStandardCasing,
  camelToStandardCasing,
  kebabToStandardCasing,
  showTimeOfDay,
  toGetRelativeTime,
  objectIdToDate,
  toCapitalizeFirstLetter,
  showDateAndTime,
  showOnlyTime,
  showOnlyYear,
  showOnlyMonthAndYear,
  showOnlyDate,
  formatDate,
  formatMoney,
  showDayOfWeek,
  showVaryingDateFormats,
  generatePaymentReference,
  calculateNoOfDays,
  countryRegionClasses,
  selectTagClasses,
  getFileFormatFromMimeType,
}