import axios from "axios"
import store from "../store/index";
import { clearAuthCredentials, getCookie } from "../utility";

let headers = {}

const hostname = window.location.hostname
const protocol = window.location.protocol
const productionBaseURL = protocol + "//" + hostname

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV == "production" ? productionBaseURL : process.env.VUE_APP_BACKEND_BASE_URL,
    headers,
})

// let csrftoken = getCookie('csrftoken')

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
// axiosInstance.defaults.xsrfCookieName = 'csrftoken'
// axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
// axiosInstance.defaults.headers.post['X-CSRFToken'] = csrftoken;
axiosInstance.defaults.withCredentials = true //For it to be able to send the csrftoken to server
// axiosInstance.defaults.credentials = "include"

axiosInstance.interceptors.request.use(
    async config => {
        let token = getCookie('fUserJwt')

        let unParsedUser = localStorage.getItem("user");

        let user
        if (unParsedUser && unParsedUser != "undefined" && unParsedUser != "") {
            user = JSON.parse(unParsedUser);
        }

        if (unParsedUser == "undefined" || unParsedUser == "") {
            localStorage.removeItem(user)
        }

        // if (token == "undefined" || token == "") {
        //     localStorage.removeItem(token)
        // }


        if (token && token != "undefined" && user) {
            // There is a valid token
            store.commit("user/SET_TOKEN", token)
            store.commit("user/SET_AUTH_USER", user)

            // config.headers.Authorization = `Token ${token}`
        }


        if (token) {
            // config.headers.Authorization = `Token ${token}`
            store.commit("user/SET_TOKEN", token)
        }

        return config
    },
    error => {
        return Promise.reject(error);
    })


axiosInstance.interceptors.response.use(response => {
    // Edit response config

    return response;
}, async error => {
    if (error.response && error.response.data && (
        error.response.data.detail == "Invalid token." ||
        error.response.data.detail == "Authentication credentials were not provided.")
    ) {
        clearAuthCredentials()

        return Promise.reject(null)
    } else {
        return Promise.reject(error);
    }
});



export default axiosInstance
