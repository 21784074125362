import axiosInstance from "../helpers/axiosInstance";

const state = {
  savedBankCards: []
};

const mutations = {
  SET_SAVED_BANK_CARDS: (state, payload) => {
    state.savedBankCards = payload;
  },
};

const actions = {
  //For a user to get their payment history
  GET_PAYMENT_HISTORY({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/payments/my-payment-transactions", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get a specific payment
  GET_SPECIFIC_PAYMENT({ }, transactionId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/payments/get-payment-transaction/${transactionId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to save their bank card authorization
  SAVE_BANK_CARD_AUTHORIZATION({commit }, paymentReference) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/payments/save-bank-card-authorization/${paymentReference}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_SAVED_BANK_CARDS", res.data.data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to fetch their saved bank cards
  FETCH_SAVED_BANK_CARDS({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("payments/get-saved-bank-cards")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_SAVED_BANK_CARDS", res.data.data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

    //For a user to delete their saved bank card
    DELETE_BANK_CARD({ commit }, bankCardId) {
      return new Promise((resolve, reject) => {
        axiosInstance.delete(`/payments/delete-saved-bank-card/${bankCardId}`)
          .then((res) => {
            const { success } = res.data;
            if (success == true) {
              commit("SET_SAVED_BANK_CARDS", res.data.data)
              resolve(res.data)
            }
          }).catch((err) => {
            reject(err.response.data)
          })
      })
    },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
