import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  SUBSCRIBE_TO_NEWSLETTER({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/newsletters/subscribe-to-newsletter", payload)
        .then(res => {
          if (res.data.success == true) {
            dispatch("user/GET_USER", null, { root: true })
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  UNSUBSCRIBE_FROM_NEWSLETTER({ dispatch }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/newsletters/unsubscribe-from-newsletter")
        .then(res => {
          if (res.data.success == true) {
            dispatch("user/GET_USER",  null, { root: true })
            resolve(res.data)
          }
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
